import { get,post} from '@/utils/axios/axios'


export const getBrandList = data => post("/item/brand/list",data)

export const addBrand = data =>post("/item/brand",data);


export const getClaimPolicyList = data => get("/m/claim/getPolicyList",data)

export const getClaimPolicyInfo = data => get("/m/claim/getPolicyInfo",data)

export const getInsuredCompanyCode = data => get("/m/claim/getInsuredCompanyCode",data)

export const getAllNeedClaimInfo = data => get("/m/claim/allNeedClaimInfo",data)

export const getAreaInfoList = data => post("/m/allAreaInfoList",data)

export const getDriveClaimAmount = data => get("/m/claim/product/driveClaimAmount",data)

export const getTiananRegionQuery = data => get("/tianan/regionQuery",data)

export const getTiananBankQuery = data => get("/tianan/bankQuery",data)

export const uploadImage = data => post("/m/uploadImage",data)

export const addClaimDrive = data => post("/m/claim/addOrEditClaimDrive",data)

export const editClaim = data => post("/m/claim/editClaim",data)

export const getClaimList = data => get("/m/claim/getClaimList",data)

export const getBankInfoList = data => post("/m/bankInfoList",data)

export const getSubBankList = data => get("/m/subBankList",data)

export const getCmpBankAreaInfoList = data => get("/m/companyBankAreaInfoList",data)

export const getCmpBankInfoList = data => get("/m/companyBankInfoList",data)

export const getDraftClaimList = data => get("/m/claim/getDraftClaimList",data)

export const getLoginQrCodeImg = data => get("/m/claim/loginQrCodeImg",data)

export const getQueryQRCode = data => get("/m/claim/queryQRCode",data)

export const getClaimExamList = data => get("/m/claim/getClaimExamList",data)


