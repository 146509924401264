<template>
  <div id="ClaimList">
    <div style="height: 28px"></div>
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text=""
        @load="onLoad"
    >
      <van-cell v-for="item in list" :key="item.id" size="large" @click="claimUpPage(item)" >
        <p style="font-size: 17px;font-weight: bolder">{{ item.planName }} </p>
        <van-row type="flex" justify="space-between">
          <van-col style="font-size: 17px;" span="10"></van-col>
          <van-col style="font-weight: bolder;text-align: right;" span="4">状态：</van-col>
          <van-col span="10" justify="end" style="text-align: right"
                   v-if="3 === item.claimStatus || 5 === item.claimStatus || 8 === item.claimStatus || 16 === item.claimStatus || 17 === item.claimStatus || 19 === item.claimStatus">
            <van-tag size="medium" type="warning">{{ claimStatusMap[item.claimStatus] }}</van-tag>
          </van-col>
          <van-col span="10" justify="end" style="text-align: right" v-else-if="9 === item.claimStatus">
            <van-tag size="medium" type="success">{{ claimStatusMap[item.claimStatus] }}</van-tag>
          </van-col>
          <van-col span="10" justify="end" style="text-align: right"
                   v-else>
            <van-tag size="medium" type="primary">{{ claimStatusMap[item.claimStatus] }}</van-tag>
          </van-col>
        </van-row>

        <van-row type="flex" justify="space-between">
          <van-col style="font-size: 17px;" span="10"></van-col>
          <van-col style="font-weight: bolder;text-align: right;" span="4">时间：</van-col>
          <van-col span="10" justify="end" style="text-align: right;">
            {{item.updateTime}}
          </van-col>
        </van-row>

        <p style="margin-top: 10px"><samp><span style="font-weight: bolder">单号：</span> {{item.policyNo}}</samp></p>
        <p><samp><span style="font-weight: bolder">被保险人：</span> {{item.insuredName}}</samp></p>
        <p><samp><span style="font-weight: bolder">备注：</span> {{item.remark}}</samp></p>

        <van-row type="flex" justify="space-between">
          <van-col style="font-size: 17px;" span="18"></van-col>
          <van-col span="6" justify="end" style="text-align: right;">
            <van-tag size="large" type="primary"> 详情 </van-tag>
          </van-col>
        </van-row>
      </van-cell>
    </van-list>
    <van-empty v-if="empty.isShow" :image="empty.image" :description="empty.description" />

    <a-modal v-model="tipsVisible" title="提示">
      <template slot="footer">
        <a-button key="back" @click="goExamQr">
          考试截图授权
        </a-button>
        <a-button key="submit" type="primary" @click="goClaimInfo">
          详情
        </a-button>
      </template>

      <p>进入详情或者考试截图授权</p>
    </a-modal>

  </div>
</template>

<script>
import {getClaimList} from "@/api/claim";

export default {
  name:"ClaimList",
  data() {
    return {
      formDate:{
        insuredCardNo:"",
        insuredName:""
      },
      list: [],
      loading: false,
      finished: false,
      tipsVisible: false,
      empty:{
        isShow:false,
        image:"default",
        description:"理赔信息不存在",
      },
      selectedClaim: {},
      claimStatusMap:{
        1:"草稿",
        2:"审核中",
        3:"初审不通过",
        4:"初审通过",
        5:"保险公司审核不通过",
        6:"保险公司审核通过",
        7:"保险公司打款中",
        8:"保险公司打款失败",
        9:"已结案",
        10:"材料问题重提审核中",
        11:"122账户问题重提审核中",
        12:"收款账户问题审核中",
        13:"加急处理中",
        14:"拒赔结案",
        15:"考试截图中",
        16:"考试截图失败",
        17:"考试截图信息错误",
        18:"考试截图成功审核中",
        19:"渠道退回",
        20:"保险公司审核中",
        21:"风险账户",
        22:"已打款",
        27:"待授权",
        28:"授权有误请自行上传截图",
      }
    };
  },
  methods: {
    onLoad() {
      // 异步更新数据
      this.formDate.insuredCardNo = this.$route.query.insuredCardNo;
      this.loading = true;
      getClaimList({
        "insuredCardNo": this.formDate.insuredCardNo,
        "page":"1",
        "limit":"30" }).then(res => {
        if (200 === res.code) {
          this.list = res.data;
          if (0 === this.list.length) {
            this.empty.isShow = true;
          }
        }else {
          this.empty.isShow = true;
          this.empty.description = res.result.msg;
        }
        this.finished = true;
      }).catch(()=>{
        this.finished = true;
        this.empty.isShow = true;
        this.empty.image = "network";
        this.empty.description = "请求失败";
      });
    },
    claimUpPage(item){
      this.selectedClaim = item;
      // 待授权
      if (27 === item.claimStatus) {
        this.tipsVisible = true;
      } else {
        //截图失败并且可以截图的
        if ((16 === item.claimStatus
                || 17 === item.claimStatus
                || 21 === item.claimStatus)
            && 2 === item.examAutoScreenshotType) {
          this.tipsVisible = true;
        } else {
          this.$router.push({path: '/claim/claimInfo', query: {claimCode: item.claimCode}});
        }

      }
    },
    goExamQr() {
      this.$router.push({path: '/claim/examQr', query: {claimCode: this.selectedClaim.claimCode}});
    },
    goClaimInfo() {
      this.$router.push({path: '/claim/claimInfo', query: {claimCode: this.selectedClaim.claimCode}});

    }
  },
}
</script>
